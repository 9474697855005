export const ListActive = [
  { name: 'Active', value: 1 },
  { name: 'Inactive', value: 2 },
];
export const ENV_NAMES = {
  DEV: "DEV",
  PROD: "PROD",
};

export const MESSAGE_SEVERITY = {
  SUCCESS: "success",
  INFO: "info",
  WARN: "warn",
  ERROR: "error",
};

export const ListingStatus = {
  UnderReview: 1,
  Active: 2,
  Inactive: 3,
  Discontinued: 4,
};

export const VenueCategories: any = [
  {
    seed_data_id: "vc1",
    type: "Premium",
  },
  {
    seed_data_id: "vc2",
    type: "Champion",
  },
];

export const zones: any = [
  { name: "East", code: "east" },
  { name: "West", code: "west" },
  { name: "North", code: "north" },
  { name: "South", code: "south" },
];

export const targetOrderTypes: any = [
  { name: "MemberShip", value:1 },
  { name: "MemberShip_Newpurchase", value:2 },
  { name: "Membership Upgrade", value:3 },
  { name: "MemberShip_Renewal", value:4 },
  { name: "PTPurchase", value:5 },
  { name: "PT_Newpurchase", value:6 },
  { name: "PT_Renewal", value:7 },
];



export const servicesItems = [
  {
    seed_data_id: "fo1",
    type: "Gym",
    description: "A premise with equipment for doing physical exercise.",
  },
  {
    seed_data_id: "fo2",
    type: "Yoga",
    description: "A Premise for doing Yoga.",
  },
  {
    seed_data_id: "fo3",
    type: "Zumba",
    description: "A Premise for doing Zumba.",
  },
  {
    seed_data_id: "fo4",
    type: "Functional Training",
    description: "A Premise for doing Crossfit training.",
  },
  {
    seed_data_id: "fo4",
    type: "Bolly Beats",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "Pilatus",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "ABT",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "Kick Boxing",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "Step Aerobics",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "TRX",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "Tabata",
    description: "",
  },
  {
    seed_data_id: "fo4",
    type: "HIT",
    description: "",
  },
];

export const amenities: any = [
  {
    seed_data_id: "ao1",
    name: "Shower",
  },
  {
    seed_data_id: "ao2",
    name: "CCTV",
  },
  {
    seed_data_id: "ao3",
    name: "Bike Parking",
  },
  {
    seed_data_id: "ao4",
    name: "Car Parking",
  },
  {
    seed_data_id: "ao5",
    name: "Locker",
  },
  {
    seed_data_id: "ao6",
    name: "Changing Room",
  },
  {
    seed_data_id: "ao7",
    name: "Wifi",
  },
  {
    seed_data_id: "ao6",
    name: "Air Conditioning",
  },
  {
    seed_data_id: "ao6",
    name: "Steam",
  },
  {
    seed_data_id: "ao6",
    name: "Sauna",
  },
  {
    seed_data_id: "ao6",
    name: "Restroom",
  },
  {
    seed_data_id: "ao6",
    name: "Cafeteria",
  },
  {
    seed_data_id: "ao6",
    name: "Valet Parking",
  },
];

export const dayOfWeekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dayOfWeek = [
  { name: "Sunday", value: 1 },
  { name: "Monday", value: 2 },
  { name: "Tuesday", value: 3 },
  { name: "Wednesday", value: 4 },
  { name: "Thursday", value: 6 },
  { name: "Friday", value: 6 },
  { name: "Saturday", value: 7 }
];

export const ATTENDANCE = [
  { name: "Present", code: true },
  { name: "Absent", code: false },
];
export const instructions = [
  { name: "Yoga Mat", seed_data_id: "yoga-mat" },
  { name: "Workout Shoes", seed_data_id: "workout-shoes" },
  { name: "Mobile Phone", seed_data_id: "mobile-phone" },
  { name: "Water Bottle", seed_data_id: "water-bottle" },
  { name: "Face Mask", seed_data_id: "face-mask" },
];

export const LEVELS = [
  { name: "Level 1", seed_data_id: 1 },
  { name: "Level 2", seed_data_id: 2 },
  { name: "Level 3", seed_data_id: 3 },
  { name: "Level 4", seed_data_id: 4 }
  
];
export const ColorCodes = [
  "#CD5C5C",
  "#E0BBE4",
  "#957DAD",
  "#C80000",
  "#009933",
  "#CC3399",
  "#FFCC00",
  "#000099",
  "#006600",
];

export const dayTiming = [
  { Hr12: "12 AM", Hr24: "00" },
  { Hr12: "01 AM", Hr24: "01" },
  { Hr12: "02 AM", Hr24: "02" },
  { Hr12: "03 AM", Hr24: "03" },
  { Hr12: "04 AM", Hr24: "04" },
  { Hr12: "05 AM", Hr24: "05" },
  { Hr12: "06 AM", Hr24: "06" },
  { Hr12: "07 AM", Hr24: "07" },
  { Hr12: "08 AM", Hr24: "08" },
  { Hr12: "09 AM", Hr24: "09" },
  { Hr12: "10 AM", Hr24: "10" },
  { Hr12: "11 AM", Hr24: "11" },
  { Hr12: "12 PM", Hr24: "12" },
  { Hr12: "01 PM", Hr24: "13" },
  { Hr12: "02 PM", Hr24: "14" },
  { Hr12: "03 PM", Hr24: "15" },
  { Hr12: "04 PM", Hr24: "16" },
  { Hr12: "05 PM", Hr24: "17" },
  { Hr12: "06 PM", Hr24: "18" },
  { Hr12: "07 PM", Hr24: "19" },
  { Hr12: "08 PM", Hr24: "20" },
  { Hr12: "09 PM", Hr24: "21" },
  { Hr12: "10 PM", Hr24: "22" },
  { Hr12: "11 PM", Hr24: "23" },
];

export const LEAD_STATUS_LIST = [
  { name: 'Hot', value: 'Active' },
  { name: 'Cold', value: 'Inactive' }
];

export const GENDER = [
  { name: 'Male', value: 1 },
  { name: 'Female', value: 2 }
];

export const TYPE_LIST = [
  { name: 'General Trainer', value: 'GeneralTrainer', text: 'GeneralTrainer' },
  { name: 'Personal Trainer', value: 'PersonalTrainer', text: 'PersonalTrainer' },
  { name: 'Housekeeping', value: 'Housekeeping', text: 'Housekeeping' },
  { name: 'Venue Manager', value: 'Manager', text: 'Manager' },
  { name: 'Member Coordinator', value: 'MC', text: 'MC' },
  {name: 'Attendance Account', value: 'AttendanceAccount', text: 'AttendanceAccount' }
];

export const EMAIL_ID_VALIDATION = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

export const PHONE_NUMBER_VALIDATION = /^\d{10}$/;

export const WEBSITE_URL_VALIDATION = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const PERMISSIONS = [
  "GetFileById",
  "GetAllFilesByRefId",
  "UploadFile",
  "DownloadFile",
  "DeleteFile",
  "SearchLeadsWithPagination",
  "GetAllVenueLead",
  "AddVenueLead",
  "UpdateVenueLead",
  "SearchVenuePagination",
  "GetVenueBaseProfiles",
  "GetVenueBaseProfileByOwner",
  "GetVenueBaseProfile",
  "CreateUpdateVenueBaseProfile",
  "DeleteVenueBaseProfile",
  "SearchCustomerWithPagination",
  "GetAllCustomers",
  "GetAllByUserId",
  "GetTrialCustomers",
  "GetRecentlyAssignedCustomers",
  "GetAllExistingCustomers",
  "Addcustomer",
  "Modifycustomer",
  "Deletecustomer",
  "SearchSubscriptionsById",
  "SearchSubscriptionsWithPagination",
  "GetSubscriptionPlanById",
  "CreateSubscriptionPackage",
  "UpdateSubscriptionPackage",
  "DeleteSubscriptionPackage",
  "SearchGenericOfferById",
  "SearchGenericOffersWithPagination",
  "GetOfferById",
  "GetAllGenericOffer",
  "CreateGenericOffer",
  "UpdateGenericoffer",
  "DeleteGenericOffer",
  "GetAllByUserId",
  "GetAllByRoleId",
  "GetAllByVenueId",
  "AddAttendance",
  "changeattendance",
  "ModifyAttendance",
  "DeleteAttendance",
  "DeleteAttendanceEntry",
  "GenerateAttendanceQRCode",
  "CheckIn",
  "GetCustomersCheckedIn",
  "GetStaffAttendanceByDateRange",
  "GetStaffAttendanceSummaryMonthly",
  "SearchVenueStaffWithPagination",
  "GetStaffById",
  "GetAllStaff",
  "AddNewStaff",
  "UpdateNewStaff",
  "DeleteNewStaff",
  "GetVenueByStaff",
  "GetStaffByVenueId",
  "GetPersonalTrainerStaff",
  "SearchGXClassByLocation",
  "SearchGXclasswithPagination",
  "GetGxScheduleById",
  "GetAllGxSchedule",
  "InsertGxSchedule",
  "UpdateGxSchedule",
  "DeleteGXSchedule",
  "GetAllGxSessions",
  "GetGxSessionById",
  "InsertGXSessions",
  "UpdateGXSessions",
  "CancelGXSessions",
  "CaptureFeedback",
  "BookSlot",
  "GetRosteringById",
  "GetAllRoster",
  "GetAllRosterByStaff",
  "AddRostering",
  "UpdateRostering",
  "DeleteRostering",
  "GetShiftById",
  "GetAllShift",
  "AddShift",
  "UpdateShift",
  "DeleteShift",
  "AddPTPackageDefinition",
  "GetPTPackageDefinitionbyId",
  "GetAllPTPackageDefinition",
  "UpdatePTPackageDefinition",
  "DeletePTPackageDefinition",
  "AddPTSubsription",
  "GetPTSubsriptionbyId",
  "GetAllPTSubscription",
  "UpdatePTSubsription",
  "DeletePTSubsription",
  "BookPTSession",
  "GetPTSessionsById",
  "GetAllPTSession",
  "UpdatePTSessions",
  "DeletePTSessions",
  "GetIncentiveSummery",
  "GetMonthlySummaryAsync",
  "GetPTActiveUsers",
  "GetPTExpiredUsers",
  "GetPTrailUsers",
  "GetPTSessionCount",
  "GetPTSessionHistory",
  "GetCustomerSubscriptionStatus"
];